<template>
    <section class="anuncios-index ml-4 mt-3">
        <tabs :tabs="arrayTabs" class="mx-0" />
        <router-view class="router-view-anuncios mt-4" />
    </section>
</template>
<script>
export default {
    data(){
        return{
        arrayTabs: [
            {
                titulo:'Solicitudes',
                ruta:"anuncios.solicitudes",
            },
            {
                titulo:'Mis anuncios',
                ruta:"anuncios.mis.anuncios",
            },
            {
                titulo:'Cartelera',
                ruta:"anuncios.cartelera",
            },
        ],
        }
    }
}
</script>
<style lang="scss" scoped>
.anuncios-index{
    .router-view-anuncios{
        // height: calc(100% - 200px);
        // background: #f5f5f5;
    }
}
</style>